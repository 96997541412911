<template>
  <div class="component-components" :size="size">
    <div class="content">
      <img class="image" src="/assets/img/image.components.svg" alt="Chracter for components page" />
      <div class="components part">
        <div class="sm-title">{{ store.state.site.lang === "ko" ? "컴포넌트" : "Components" }}</div>
        <span v-if="store.state.site.lang === 'ko'">퍼블레싱은 다양한 컴포넌트를 제공합니다. 사용 방법을 보시려면 우측 링크를 클릭해주세요. </span>
        <span v-else>Publessing provides various components. Click the link on the right to see how to use it. </span>
        <router-link :to="'/' + store.state.site.lang + '/learn'"> [{{ store.state.site.lang === "ko" ? "시작하기" : "Let's learn" }}] </router-link>
        <div class="box" :style="state.box.style">
          <Box ref="boxRef" theme="light" :size="size" sector="component" :callback="callback" :change="change" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import Box from "./Box";
import { onMounted } from "@vue/runtime-core";

export default {
  props: {
    callback: Function,
    load: Function,
    size: String,
  },
  components: { Box },
  setup() {
    const store = useStore();
    const state = reactive({
      box: {
        style: {
          height: "500px",
        },
      },
    });
    const boxRef = ref(null);

    const change = (size) => {
      state.box.style.height = size + "px";
    };

    onMounted(() => {
      boxRef.value.init();
    });

    return { store, state, boxRef, change };
  },
};
</script>
<style lang="scss" scoped>
.component-components {
  .part {
    &.components {
      .box {
        margin-top: 30px;
        background: #1e1e1e;
        height: 670px;
      }
    }
  }
}
</style>
